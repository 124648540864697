import { useState, useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { Input, SelectInput } from '../Input';
import Grid from '@mui/material/Grid';
import { SelectChangeEvent } from '@mui/material/Select';
import { FormButton } from '../Button';
import { DialogContent, DialogActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from '../../utils/redux';
import { inviteUser } from '../../redux/actions/userAction';
import validator from 'validator';
import { errorsSelector as errorsArray } from '../../redux/selectors/ui';
import {
  isAdminSelector,
  isBuildingManagerSelector,
  isManagerSelector,
  roleSelector as userRole,
} from '../../redux/selectors/user';
import { RoleOption } from '../../const/types';
import {
  // adminRoles,
  buildingManagerRoles,
  conciergeRoles,
  managerRoles,
  superAdminRoles,
} from '../../const/arrays';
import s from './style.module.css';
import {
  companyIdSelector,
  companySelector,
  companyStatusSelector,
} from '../../redux/selectors/company';
import {
  buildingIdSelector,
  buildingsFullListSelector,
} from '../../redux/selectors/building';
import ColorType from '../Theme/ColorType';
import { useTranslation } from 'react-i18next';

type PopupProps = {
  open: boolean;
  handleClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
};

type ErrorProp = {
  email?: string;
  company?: string;
  role?: string;
};

const Popup = ({ open, handleClose }: PopupProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const errorsSelector = useAppSelector(errorsArray);
  const roleSelector = useAppSelector(userRole);
  const isAdmin = useAppSelector(isAdminSelector);
  const isManager = useAppSelector(isManagerSelector);
  const isBuildingManager = useAppSelector(isBuildingManagerSelector);
  const userCompany = useAppSelector(companySelector);
  const companyId = useAppSelector(companyIdSelector);
  const companyStatus = useAppSelector(companyStatusSelector);
  const buildingsId = useAppSelector(buildingsFullListSelector);
  const buildingId = useAppSelector(buildingIdSelector);
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [building, setBuilding] = useState<any>([]);
  const [role, setRole] = useState('Choose a role');
  const [isSendPin, setIsSendPin] = useState(true);
  const [errors, setErrors] = useState<ErrorProp>({});
  const [openSelect, setOpen] = useState(false);
  const isAdminOrNoCompany = isAdmin && !companyId;
  const isAdminAndCompanyDisabled = isAdmin && !!companyId && !companyStatus;

  const handleDisplayRoles = (): RoleOption[] => {
    switch (roleSelector) {
      case 'super-admin':
        if (companyId) return managerRoles;
        return superAdminRoles;
      case 'admin':
      case 'manager':
        return managerRoles;
      case 'building-manager':
        return buildingManagerRoles;
      case 'consierge':
        return conciergeRoles;
      default:
        return conciergeRoles;
    }
  };

  const Roles = handleDisplayRoles();

  useEffect(() => {
    setErrors(errorsSelector);
    return () => {
      setErrors({});
      setEmail('');
      setCompany('');
      setRole(Roles[0].value);
    };
  }, [Roles, errorsSelector]);

  useEffect(() => {
    if (!isAdminOrNoCompany && !!userCompany) {
      setCompany(userCompany.name);
      if (!building.includes(buildingId) && buildingId) {
        setBuilding((prev: any) => [...prev, buildingId]);
      }
    }
    return () => {
      setCompany('');
      setBuilding([]);
    };
  }, [buildingId, isAdminOrNoCompany, userCompany]);

  const handleChangeInput = (text: string) => {
    setEmail(text);
  };

  const handleChangeCompany = (text: string) => {
    setCompany(text);
  };

  const handleChangeRole = (event: SelectChangeEvent) => {
    setRole(event.target.value);
  };

  const handleOpenSelect = () => {
    setOpen(true);
  };

  const handleCloseSelect = () => {
    setOpen(false);
  };

  const handleChangeBuildings = (event: any) => {
    const {
      target: { value },
    } = event;
    const isAllSelected = value.find((e: number) => e === 0);
    if (isAllSelected === 0) {
      const newValues = [...buildingsId];
      const allIds = newValues.filter((e) => e.value !== 0).map((e) => e.value);
      setBuilding(allIds);
      handleCloseSelect();
    } else {
      setBuilding(value);
    }
  };

  const validateData = () => {
    const errors: ErrorProp = {};
    if (!validator.isEmail(email)) {
      errors.email = 'A valid email is required';
    }
    if (validator.isEmpty(company) && isAdminOrNoCompany) {
      errors.company = 'A valid company name is required';
    }
    if (role === Roles[0].value) {
      errors.role = 'Role is required';
    } else if (isAdminAndCompanyDisabled) {
      const snackBar = {
        isOpen: true,
        text: t('users-disabled-message'),
        severity: 'error',
      };
      dispatch({ type: 'SET_SNACK', payload: snackBar });
    }
    return errors;
  };

  const handleSendData = () => {
    const errors = validateData();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    setErrors({});
    const data = {
      email: email,
      role: role,
      ...(isAdminOrNoCompany && { company_name: company, generate_pin: true }),
      ...((isManager || isBuildingManager) && { generate_pin: isSendPin }),
      ...(!isAdminOrNoCompany &&
        !!userCompany && { company_id: companyId, buildings: building }),
    };
    dispatch(
      inviteUser(data, handleCloseAction, isAdmin, companyId, buildingId)
    );
  };

  const handleCloseAction = () => {
    setEmail('');
    setCompany('');
    setBuilding([]);
    setRole('Choose a role');
    setIsSendPin(true);
    setErrors({});
    setOpen(false);
    handleClose();
  };

  return (
    <TransparentDialog onClose={handleCloseAction} open={open}>
      <DialogTitleContainer>{t('users-popup-label')}</DialogTitleContainer>
      <DialogContentContainer>
        <GridContainer container>
          <Grid xs={12} sm={5} item>
            <Input
              label="Email"
              placeholder="username@domain.com"
              value={email}
              hasBorder
              OnChange={handleChangeInput}
              error={!!errors?.email}
              errorMessage={errors?.email}
            />
          </Grid>
          <Grid xs={12} sm={5} item>
            <SelectInput
              label={t('user-role-label')}
              placeholder={t('users-popup-role-placeholder')}
              values={Roles}
              value={role}
              OnChange={handleChangeRole}
              error={!!errors?.role}
              errorMessage={errors?.role}
            />
            {role === 'Gestionnaire' && (
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{
                  color: '#47A3FF',
                  fontSize: 12,
                  fontWeight: 400,
                  marginTop: 10,
                  paddingLeft: 8,
                }}
              >
                {t('users-popup-role-notice')}
              </Typography>
            )}
          </Grid>
          {!isAdminOrNoCompany && (
            <Grid xs={12} sm={5} item>
              <SelectInput
                label={t('users-popup-building')}
                values={buildingsId}
                value={building}
                OnChange={handleChangeBuildings}
                open={openSelect}
                onOpen={handleOpenSelect}
                onClose={handleCloseSelect}
                multiple
              />
            </Grid>
          )}
          {(isManager || isBuildingManager) && (
            <Grid xs={12} sm={5} item className={s.pinContainer}>
              <PinContainer>
                <PinText>{t('users-popup-pin')}</PinText>
                <ButtonWrapper onClick={() => setIsSendPin((prev) => !prev)}>
                  {isSendPin && <RadioButton />}
                </ButtonWrapper>
              </PinContainer>
            </Grid>
          )}
          {isAdminOrNoCompany && (
            <Grid xs={12} sm={12} className={s.companyContainer} item>
              <Input
                label={t('users-poppup-company-label')}
                placeholder={t('users-poppup-company-placeholder')}
                value={company}
                hasBorder
                OnChange={handleChangeCompany}
                error={!!errors?.company}
                errorMessage={errors?.company}
              />
            </Grid>
          )}
        </GridContainer>
      </DialogContentContainer>
      <DialogActionsContainer>
        <FormButton
          buttonLabel={t('button-cancel')}
          color={'grey'}
          OnClick={handleCloseAction}
          style={{
            marginRight: 10,
            borderRadius: 6,
            border: '1px solid #F0F0F0',
          }}
        />
        <FormButton
          buttonLabel={t('button-invite')}
          color={'primary'}
          OnClick={handleSendData}
          style={{
            borderRadius: 6,
          }}
        />
      </DialogActionsContainer>
    </TransparentDialog>
  );
};

const TransparentDialog = styled(Dialog)(() => ({
  '& .MuiBackdrop-root': {
    backgroundColor: '#00000033',
  },
  '& .MuiDialog-paper': {
    width: '80%',
    maxWidth: '80%',
    borderRadius: 16,
  },
}));

const DialogTitleContainer = styled(DialogTitle)(() => ({
  paddingTop: 32,
  paddingInline: 48,
  fontSize: 28,
  fontWeight: 600,
  fontFamily: 'Inter',
  color: '#2E3646',
}));

const DialogContentContainer = styled(DialogContent)(() => ({
  paddingTop: '32px !important',
  paddingInline: 30,
  paddingBottom: 32,
}));

const DialogActionsContainer = styled(DialogActions)(() => ({
  paddingInline: 48,
  paddingBottom: 32,
}));

const GridContainer = styled(Grid)(() => ({
  gap: 15,
  justifyContent: 'center',
}));

const PinContainer = styled('div')(() => ({
  paddingLeft: '8px',
  paddingRight: '8px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '8px',
  display: 'inline-flex',
}));

const PinText = styled('span')(() => ({
  color: 'black',
  fontSize: '14px',
  fontFamily: 'Rubik',
  fontWeight: '500',
  lineHeight: '22px',
  wordWrap: 'break-word',
}));

const ButtonWrapper = styled('div')(() => ({
  padding: '4px',
  borderRadius: '5px',
  border: '0.50px black solid',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: '8px',
  display: 'flex',
  cursor: 'pointer',
  width: 22,
  height: 22,
}));

const RadioButton = styled('div')(() => ({
  width: '12px',
  height: '12px',
  background: ColorType.primary,
  borderRadius: '5px',
}));

export default Popup;
