import axios from 'axios';
import { isTokenExpired } from './helper';
import { logoutUser } from '../redux/actions/userAction';

const setupAxiosInterceptors = (dispatch, message) => {
  axios.interceptors.response.use(
    (response) => {
      // If the response is successful, just return it
      return response;
    },
    (error) => {
      const token = localStorage.getItem('IdToken');
      if (
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/forgot-password' &&
        window.location.pathname !== '/password-reset' &&
        window.location.pathname !== '/privacy' &&
        window.location.pathname !== '/tos'
      ) {
        // Check for expired token or 401 error
        if (
          !token ||
          isTokenExpired(token) ||
          error?.response?.status === 401
        ) {
          // Logout user if token is expired or not found
          localStorage.removeItem('IdToken'); // Clear token
          // navigate('/login'); // Redirect to login page
          dispatch(logoutUser());

          // Optionally, you can display a logout message
          alert(message);
        }
      }

      // Return the error to be handled by the calling function
      return Promise.reject(error);
    }
  );
};

export default setupAxiosInterceptors;
