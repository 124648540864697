import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Input, PasswordInput, SelectInput } from '../Input';
import { FormButton } from '../Button';
import { styled } from '@mui/system';
import H3 from '../Text/H3';
import { SelectChangeEvent } from '@mui/material/Select';
import CardItem from './CardItem';
import { registerUser } from '../../redux/actions/userAction';
import { useAppDispatch, useAppSelector } from '../../utils/redux';
import validator from 'validator';
import { errorsSelector as errorsArray } from '../../redux/selectors/ui';
import { Alert } from '../Snapbar';
import ColorType from '../Theme/ColorType';
import { Checkbox } from '@mui/material';
import PopupTos from '../Modal/PopupTos';
import PopupPrivacy from '../Modal/PopupPrivacy';
import { useTranslation } from 'react-i18next';

type MethodOption = {
  value: string;
  label: string;
  placeholder?: boolean;
};

type ErrorProp = {
  email?: string;
  name?: string;
  password?: string;
  confirm?: string;
  phone?: string;
  message?: string;
};

const methods: MethodOption[] = [
  { value: 'email', label: 'Via e-mail' },
  // { value: 'phone', label: 'Via Phone' },
];

type Registerrops = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setData: React.Dispatch<React.SetStateAction<object>>;
};

const RegisterCard = ({ setStep, setData }: Registerrops) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const errorsSelector = useAppSelector(errorsArray);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const params = new URLSearchParams(location.search);
  const urlCode = params.get('code');
  const urlEmail = params.get('email');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [method, setMethod] = useState(methods[0].value);
  const [errors, setErrors] = useState<ErrorProp>({});
  const [tosOpen, setTosOpen] = useState(false);
  const [tosChecked, setTosChecked] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const isAcceptedTerms = tosChecked && privacyChecked;

  useEffect(() => {
    setErrors(errorsSelector);
    return () => {
      setErrors({});
      setEmail('');
      setName('');
      setPhone('');
      setPassword('');
      setConfirmPassword('');
    };
  }, [errorsSelector]);

  useEffect(() => {
    if (urlCode && urlEmail) {
      setCode(urlCode);
      setEmail(urlEmail);
    }
  }, [urlCode, urlEmail]);

  const handleChangeEmail = (text: string) => {
    setEmail(text);
  };

  const handleChangeName = (text: string) => {
    setName(text);
  };

  // const handleChangePhone = (text: string) => {
  //   setPhone(text);
  // };

  const handleChangePassword = (text: string) => {
    setPassword(text);
  };

  const handleChangeConfirmPassword = (text: string) => {
    setConfirmPassword(text);
  };

  const handleChangeMethod = (event: SelectChangeEvent) => {
    setMethod(event.target.value);
  };

  const handleTosChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTosChecked(event.target.checked);
  };

  const handlePrivacyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrivacyChecked(event.target.checked);
  };

  const validateData = () => {
    const errors: ErrorProp = {};
    const passwordValidation = !validator.isStrongPassword(password);
    const confirmPasswordValidation = !(confirmPassword === password);
    // const phoneValidation =
    //   method === methods[1].value && !validator.isMobilePhone(phone);

    if (!validator.isEmail(email)) {
      errors.email = t('email-required');
    }
    if (!name) {
      errors.name = t('name-required');
    }
    if (passwordValidation) {
      errors.password = t('reset-error');
    }
    if (confirmPasswordValidation) {
      errors.confirm = t('password-missmatch');
    }
    // if (phoneValidation) {
    //   errors.phone = 'A valid name is required';
    // }
    return errors;
  };

  const handleSendData = () => {
    const errors = validateData();
    if (Object.keys(errors).length) {
      setErrors(errors);
      return;
    }
    setErrors({});
    const data = {
      code,
      name,
      email,
      '2fa_method': method,
      phone,
      password,
    };
    setData(data);
    dispatch(registerUser({ data, setStep }));
  };

  const handleOpenTos = () => setTosOpen(true);
  const handleCloseTos = () => {
    setTosChecked(false);
    setTosOpen(false);
  };

  const handleClickTos = () => {
    setTosChecked(true);
    setTosOpen(false);
  };

  const handleOpenPrivacy = () => setPrivacyOpen(true);
  const handleClosePrivacy = () => {
    setPrivacyChecked(false);
    setPrivacyOpen(false);
  };

  const handleClickPrivacy = () => {
    setPrivacyChecked(true);
    setPrivacyOpen(false);
  };

  return (
    <CardItem title="BuildUp!" subTitle={t('login-title')} bgColor="#1A1A1A">
      <>
        <H3
          text={t('register-title')}
          style={{ paddingBottom: 16, color: 'white' }}
        />
        {errors?.message && (
          <Alert
            text={errors?.message}
            type="info"
            bgColor={ColorType.lightRed}
            txtColor={ColorType.darkRed}
            style={{ margin: '24px 0px 16px 0px' }}
          />
        )}
        <Input
          placeholder={t('login-email-label')}
          value={email}
          disabled
          type="email"
          OnChange={handleChangeEmail}
          error={!!errors?.email}
          errorMessage={errors?.email}
        />
        <Spacer />
        <Input
          placeholder={t('register-fullname')}
          value={name}
          type="text"
          OnChange={handleChangeName}
          error={!!errors?.name}
          errorMessage={errors?.name}
        />
        <Spacer />
        <PasswordInput
          id="register-password"
          placeholder={t('login-password-label')}
          value={password}
          OnChange={handleChangePassword}
          error={!!errors?.password}
          errorMessage={errors?.password}
          color="white"
        />
        <Spacer />
        <PasswordInput
          id="register-repeat-password"
          placeholder={t('register-confirm-password')}
          value={confirmPassword}
          OnChange={handleChangeConfirmPassword}
          error={!!errors?.confirm}
          errorMessage={errors?.confirm}
          color="white"
        />
        <H3
          text={t('register-2fa')}
          style={{
            paddingTop: 16,
            paddingBottom: 8,
            paddingLeft: 8,
            fontSize: 14,
            fontWeight: 600,
            lineHeight: '22px',
            color: 'white',
          }}
        />
        <SelectInput
          placeholder={t('register-method')}
          values={methods}
          value={method}
          OnChange={handleChangeMethod}
        />
        {/* {method === 'phone' && (
          <>
            <Spacer />
            <Input
              placeholder="Phone Number"
              value={phone}
              type="number"
              OnChange={handleChangePhone}
              error={!!errors?.phone}
              errorMessage={errors?.phone}
            />
          </>
        )} */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={tosChecked}
            onChange={handleTosChange}
            sx={{
              color: 'white',
              '&.Mui-checked': {
                color: '#FF0018',
              },
            }}
          />
          <span
            style={{
              fontSize: 14,
              fontWeight: 600,
              color: '#B4B4B4',
            }}
          >
            {t('register-read')}{' '}
            <PopUpSpan onClick={handleOpenTos}>{t('register-tos')}</PopUpSpan>
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={privacyChecked}
            onChange={handlePrivacyChange}
            sx={{
              color: 'white',
              '&.Mui-checked': {
                color: '#FF0018',
              },
            }}
          />
          <span
            style={{
              fontSize: 14,
              fontWeight: 600,
              color: '#B4B4B4',
            }}
          >
            {t('register-read')}{' '}
            <PopUpSpan onClick={handleOpenPrivacy}>
              {t('register-pp')}
            </PopUpSpan>
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <FormButton
            buttonLabel={t('register-finish')}
            color={'primary'}
            OnClick={handleSendData}
            disabled={!isAcceptedTerms}
            style={{
              borderRadius: 6,
              // float: 'right',
              alignSelf: 'center',
              marginTop: 40,
              padding: '8px 16px',
            }}
            sx={{
              '&.Mui-disabled': {
                background: '#eaeaea',
                color: '#c0c0c0',
              },
            }}
          />
          <FormButton
            buttonLabel={t('button-back')}
            variant="text"
            style={{ alignSelf: 'center' }}
            OnClick={() => navigate('/login')}
          />
        </div>
        <PopupTos
          open={tosOpen}
          handleClose={handleCloseTos}
          handleClickTos={handleClickTos}
        />
        <PopupPrivacy
          open={privacyOpen}
          handleClose={handleClosePrivacy}
          handleClickPrivacy={handleClickPrivacy}
        />
      </>
    </CardItem>
  );
};

const Spacer = styled('div')`
  margin-top: 4px;
  margin-bottom: 4px;
`;

const PopUpSpan = styled('span')`
  color: #ff0018;
  cursor: pointer;
  text-decoration: unset;
`;

export default RegisterCard;
