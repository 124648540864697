import axios from 'axios';
import {
  CLEAR_ERRORS,
  LOADING_UI,
  SET_ADMIN_MENU,
  SET_BUILDING_MANAGER_MENU,
  SET_ERRORS,
  SET_ERROR_MESSAGE,
  SET_MANAGER_MENU,
  SET_SNACK,
  SET_SUPER_ADMIN_MENU,
  SET_UNAUTHENTICATED,
  SET_USER,
  SET_USER_FIRST_AUTH,
  SET_USER_SECOND_AUTH,
  UPDATE_USER,
} from '../types';
import {
  adminMenu,
  managerMenu,
  buildingManagerMenu,
} from '../../const/arrays';
import { getAdminUsers, getUsers } from './usersAction';
import { getCompanies } from './companyAction';
import i18n from '../../i18n/config';
import { getBuildings } from './buildingAction';

export const setAuthorizationHeader = (token) => {
  const IdToken = `Bearer ${token}`;
  localStorage.setItem('IdToken', IdToken);
  axios.defaults.headers.common['Authorization'] = IdToken;
};

export const setMenuItems = (role, dispatch) => {
  switch (role) {
    case 'super-admin':
      return dispatch({ type: SET_SUPER_ADMIN_MENU, payload: adminMenu });
    case 'admin':
      return dispatch({ type: SET_ADMIN_MENU, payload: adminMenu });
    case 'manager':
      return dispatch({ type: SET_MANAGER_MENU, payload: managerMenu });
    case 'building-manager':
      return dispatch({
        type: SET_BUILDING_MANAGER_MENU,
        payload: buildingManagerMenu,
      });
    default:
      return dispatch({
        type: SET_BUILDING_MANAGER_MENU,
        payload: buildingManagerMenu,
      });
  }
};

export const getUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    const res = await axios.get('/user/profile');
    dispatch({ type: SET_USER, payload: res?.data?.data });
    dispatch({ type: CLEAR_ERRORS });
  } catch (err) {
    const errors = err?.response?.data?.data;
    dispatch({ type: SET_ERRORS, payload: errors?.errors });
  }
};

export const updateInfoUser =
  ({ data }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      await axios.post('/user/profile', data);
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: UPDATE_USER, payload: data });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text: currentLanguage === 'en' ? 'Data Updated' : 'Donnée mise à jour',
        severity: 'success',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      dispatch({ type: SET_ERROR_MESSAGE, payload: errors?.message });
    }
  };

export const updatePasswordUser =
  ({ data }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      await axios.post('/user/profile/password', data);
      dispatch({ type: CLEAR_ERRORS });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text:
          currentLanguage === 'en'
            ? 'Password Updated'
            : 'Mot de passe mis à jour',
        severity: 'success',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    } catch (err) {
      const errors = err?.response?.data?.data;
      if (errors?.oldPassword) dispatch({ type: SET_ERRORS, payload: errors });
      else dispatch({ type: SET_ERRORS, payload: errors?.errors });
    }
  };

export const verifyUserPhone =
  ({ data, handleOpen }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      await axios.post(`/otp/verification/step/1`, data);
      dispatch({ type: CLEAR_ERRORS });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text: currentLanguage === 'en' ? 'Message Sent' : 'Message envoyé',
        severity: 'success',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
      handleOpen();
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const verifyUserPhoneCode =
  ({ data, handleClose, setIsPhoneVerified }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      await axios.post(`/otp/verification/step/2`, data);
      dispatch({ type: CLEAR_ERRORS });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text:
          currentLanguage === 'en'
            ? 'Verification complete'
            : 'Verification terminée',
        severity: 'success',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
      setIsPhoneVerified(true);
      handleClose();
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const inviteUser =
  (data, handleClose, isAdmin, companyId, buildingId) => async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      await axios.post('/user/invite', data);
      dispatch({ type: CLEAR_ERRORS });
      if (isAdmin) {
        if (companyId) {
          dispatch(getAdminUsers(companyId, buildingId));
        } else {
          dispatch(getUsers());
        }
        dispatch(getCompanies());
      } else {
        dispatch(getUsers());
      }

      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text:
          currentLanguage === 'en' ? 'User invited!' : 'Utilisateur invité !',
        severity: 'success',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
      handleClose();
    } catch (err) {
      const errors = err?.response?.data?.data;
      const currentLanguage = i18n.language;
      const message =
        errors?.message === 'The selected buildings.0 is invalid.'
          ? currentLanguage === 'en'
            ? 'Please create a building first!'
            : "Veuillez créer un batiment d'aboard"
          : errors?.message;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const ReinviteUser = (userId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    await axios.post(`/user/invite/resend/${userId}`);
    dispatch({ type: CLEAR_ERRORS });
    dispatch(getUsers());
    const currentLanguage = i18n.language;
    const snackBar = {
      isOpen: true,
      text: currentLanguage === 'en' ? 'Mail Sent!' : 'Email envoyé !',
      severity: 'success',
    };
    dispatch({ type: SET_SNACK, payload: snackBar });
  } catch (err) {
    const errors = err?.response?.data?.data;
    const message = errors?.message;
    dispatch({ type: SET_ERRORS, payload: errors?.errors });
    const snackBar = {
      isOpen: true,
      text: message,
      severity: 'error',
    };
    dispatch({ type: SET_SNACK, payload: snackBar });
  }
};

export const registerUser =
  ({ data, setStep }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      const { password, ...newData } = data;
      await axios.post('/register', data);
      dispatch({ type: CLEAR_ERRORS });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text: currentLanguage === 'en' ? 'Account Created' : 'Compte Crée',
        severity: 'success',
      };
      dispatch({ type: SET_USER_FIRST_AUTH, payload: newData });
      dispatch({ type: SET_SNACK, payload: snackBar });
      setStep((prev) => prev + 1);
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      dispatch({ type: SET_ERROR_MESSAGE, payload: errors?.message });
    }
  };

export const loginUser =
  ({ data, setStep }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      const { password, token, ...newData } = data;
      const res = await axios.post('/login', data);
      if (res.data?.data?.token) {
        setAuthorizationHeader(res.data?.data?.token);
        newData['role'] = res.data?.data?.role;
        newData['company'] = res.data?.data?.company;
        dispatch({ type: CLEAR_ERRORS });
        setMenuItems(newData.role, dispatch);
        dispatch({ type: SET_USER_SECOND_AUTH, payload: newData });
        dispatch(getBuildings());
        if (newData['role'] === 'super-admin') {
          return dispatch(getCompanies());
        }
        return;
      }
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: SET_USER_FIRST_AUTH, payload: newData });
      setStep && setStep((prev) => prev + 1);
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      dispatch({ type: SET_ERROR_MESSAGE, payload: errors?.message });
    }
  };

export const verifyUserRegister =
  ({ data, setStep }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      const { password, token, ...newData } = data;
      const res = await axios.post('/otp/validate/register', data);
      setAuthorizationHeader(res.data?.data?.token);
      newData['role'] = res.data?.data?.role;
      newData['company'] = res.data?.data?.company;
      newData['buildings'] = res.data?.data?.buildings;
      dispatch({ type: CLEAR_ERRORS });
      dispatch({ type: SET_USER_SECOND_AUTH, payload: newData });
      setMenuItems(newData.role, dispatch);
      setStep((prev) => prev - 1);
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const verifyUserLogin =
  ({ data, setStep, navigate }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      const { password, token, ...newData } = data;
      const res = await axios.post('/otp/validate/login', data);
      setAuthorizationHeader(res.data?.data?.token);
      newData['role'] = res.data?.data?.role;
      newData['company'] = res.data?.data?.company;
      newData['buildings'] = res.data?.data?.buildings;
      dispatch({ type: SET_USER_SECOND_AUTH, payload: newData });
      setMenuItems(newData.role, dispatch);
      dispatch(getUser());
      // newData?.role === 'manager' && dispatch(getBuildings());
      dispatch({ type: CLEAR_ERRORS });
      setStep((prev) => prev - 1);
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const sendEmailforgotPassword =
  ({ email, setStep }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      await axios.post('/forgot-password', { email });
      dispatch({ type: CLEAR_ERRORS });
      setStep((prev) => prev + 1);
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      dispatch({ type: SET_ERROR_MESSAGE, payload: errors?.message });
    }
  };

export const resetPassword =
  ({ data, navigate }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      await axios.post('/reset-password', data);
      dispatch({ type: CLEAR_ERRORS });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text:
          currentLanguage === 'en' ? 'Password changed' : 'Mot de passe changé',
        severity: 'success',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
      navigate('/');
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      dispatch({ type: SET_ERROR_MESSAGE, payload: errors?.message });
    }
  };

export const DeleteUserAccount =
  ({ handleClose }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      await axios.post(`/user/delete`);
      dispatch({ type: CLEAR_ERRORS });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text: currentLanguage === 'en' ? 'Account Deleted!' : 'Compte supprimé',
        severity: 'success',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
      handleClose();
      dispatch(logoutUser());
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const logoutUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    // await axios.post('/logout');
    localStorage.removeItem('persist:root');
    localStorage.removeItem('IdToken');
    delete axios.defaults.headers.common['Authorization'];
    dispatch({ type: SET_UNAUTHENTICATED });
    dispatch({ type: CLEAR_ERRORS });
  } catch (err) {
    const errors = err?.response?.data?.data;
    dispatch({ type: SET_ERRORS, payload: errors?.errors });
    const snackBar = {
      isOpen: true,
      text: errors?.message,
      severity: 'error',
    };
    dispatch({ type: SET_SNACK, payload: snackBar });
  }
};
