import { useEffect } from 'react';
import { ActionButton } from '../components/Button';
import Path from '../components/Link/Path';
import { useNavigate } from 'react-router';
import { BuildingTable } from '../components/Tables';
import { styled } from '@mui/system';
import { useAppDispatch, useAppSelector } from '../utils/redux';
import {
  getAdminBuildings,
  getBuildings,
} from '../redux/actions/buildingAction';
import {
  isAdminSelector,
  isManagerSelector,
  nameSelector,
  roleSelector,
} from '../redux/selectors/user';
import { companyIdSelector } from '../redux/selectors/company';
import { useTranslation } from 'react-i18next';
import { setAxiosHeaders } from '../utils/helper';

const BuildingManagement = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isAdmin = useAppSelector(isAdminSelector);
  const isManager = useAppSelector(isManagerSelector);
  const companyId = useAppSelector(companyIdSelector);
  const userName = useAppSelector(nameSelector);
  const role = useAppSelector(roleSelector);

  useEffect(() => {
    setAxiosHeaders();
  }, []);

  useEffect(() => {
    (isAdmin || isManager) && companyId
      ? dispatch(getAdminBuildings(companyId))
      : dispatch(getBuildings());
    !isAdmin && dispatch(getBuildings());
  }, [companyId, dispatch, isAdmin, isManager]);

  const handleClick = () => {
    navigate('add-building');
  };

  const handleDisplayRole = (role: string) => {
    if (role === 'super-admin') return t('super-admin-role');
    if (role === 'admin') return t('admin-role');
    if (role === 'manager') return t('manager-role');
    if (role === 'building-manager') return t('building-manager-role');
  };

  return (
    <Container>
      <HeaderContainer>
        <StyledDiv>
          <Path />
          <TitleSpan>
            {userName},{' '}
            <DescriptionSpan style={{ fontSize: 24 }}>
              {handleDisplayRole(role)}
            </DescriptionSpan>
          </TitleSpan>
          <DescriptionSpan>{t('buildings-description')}</DescriptionSpan>
        </StyledDiv>
        {(isAdmin || isManager) && (
          <ButtonContainer>
            <ActionButton
              buttonLabel={t('buildings-button-label')}
              color={'primary'}
              OnClick={handleClick}
              style={{
                borderRadius: 6,
              }}
            />
          </ButtonContainer>
        )}
      </HeaderContainer>
      <BuildingTable />
    </Container>
  );
};

const Container = styled('div')`
  padding: 32px;
  width: -webkit-fill-available;
`;

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const StyledDiv = styled('div')`
  display: flex;
  flex-direction: column;
`;

const TitleSpan = styled('span')`
  font-size: 28px;
  font-weight: bold;
  font-family: Rubik;
`;

const DescriptionSpan = styled('span')`
  font-size: 16px;
  font-weight: 300;
  color: #4a4a4a;
  font-family: Inter;
`;

const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export default BuildingManagement;
