import { styled } from '@mui/system';
import { useAppDispatch, useAppSelector } from '../utils/redux';
import { nameSelector } from '../redux/selectors/user';
import { ActionButton } from '../components/Button';
import AuthBackground from '../components/svg/AuthBackground.svg';
import ColorType from '../components/Theme/ColorType';
import { QRCode } from 'react-qrcode-logo';
import { logoutUser } from '../redux/actions/userAction';
import { useNavigate } from 'react-router-dom';
import { Apple, Playstore } from '../components/svg';

const Redirection = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userName = useAppSelector(nameSelector);

  const isIOS = () => {
    return /Macintosh|iPhone|iPad|iPod/i.test(navigator.userAgent);
  };

  const isAppInstalled = () => {
    const userAgent = navigator.userAgent;
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      );

    // Check if the app's custom URL can be opened
    const appScheme = 'buildup://';
    const appInstalled =
      isMobile && window.location.href.indexOf(appScheme) !== -1;

    return appInstalled;
  };
  const appInstalled = isAppInstalled();
  const fallbackUrl = isIOS()
    ? 'https://apps.apple.com/us/app/buildup/id6471973139'
    : 'https://play.google.com/store/apps/details?id=com.buildupmobile';
  const appUrl = !appInstalled
    ? 'buildup://'
    : isIOS()
    ? 'https://apps.apple.com/us/app/buildup/id6471973139'
    : 'https://play.google.com/store/apps/details?id=com.buildupmobile';

  const handleRedirection = () => {
    const appScheme = 'buildup://';
    window.location.href = appScheme;
    setTimeout(() => {
      window.location.href = fallbackUrl;
    }, 500);
  };

  const handleOpenGoogle = () => {
    window.location.href =
      'https://play.google.com/store/apps/details?id=com.buildupmobile';
  };

  const handleOpenApple = () => {
    window.location.href = 'https://apps.apple.com/us/app/buildup/id6471973139';
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/');
  };

  return (
    <Container>
      <HeaderContainer isBg>
        <StyledDiv>
          <HeaderWrapper>
            <IconContainer></IconContainer>
            <BigTitleSpan>Registration Complete</BigTitleSpan>
          </HeaderWrapper>
        </StyledDiv>
      </HeaderContainer>
      <HeaderContainer>
        <Wrapper>
          <StyledDiv>
            <TitleSpan>You're all set, {userName}!</TitleSpan>
            <DescriptionSpan>
              Your account was successfully created, proceed to the BuildUp
              application and let's get started.
            </DescriptionSpan>
            <ButtonContainer>
              <ActionButton
                buttonLabel="Open the App"
                OnClick={handleRedirection}
                style={{
                  borderRadius: 6,
                  backgroundColor: 'black',
                  padding: '8px 15px',
                }}
              />
              <ActionButton
                buttonLabel="Log Out"
                OnClick={handleLogout}
                style={{
                  borderRadius: 6,
                  backgroundColor: '#B2B2B2',
                  color: 'black',
                  padding: '8px 15px',
                }}
              />
            </ButtonContainer>
          </StyledDiv>
          <QrContainer>
            <QrImage value={appUrl} />
            <span>Or Scan the QR Code</span>
            <StoresContainer>
              <PlayContainer onClick={handleOpenGoogle}>
                <PlayIcon />
                <StoreTextWrapper>
                  <StoreCTA>GET IT ON</StoreCTA>
                  <StoreText>Google Play</StoreText>
                </StoreTextWrapper>
              </PlayContainer>
              <PlayContainer onClick={handleOpenApple}>
                <AppleIcon fill={ColorType.background} />
                <StoreTextWrapper>
                  <StoreCTA>Download on the</StoreCTA>
                  <StoreText>App Store</StoreText>
                </StoreTextWrapper>
              </PlayContainer>
            </StoresContainer>
          </QrContainer>
        </Wrapper>
      </HeaderContainer>
    </Container>
  );
};

const Container = styled('div')`
  background-image: url(${AuthBackground});
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  @media (max-width: 710px) {
    contain: strict;
  }
  @media (max-height: 638px) {
    overflow-y: scroll;
    height: auto;
  }
`;

type HeaderContainerProps = {
  isBg?: boolean;
};

const HeaderContainer = styled('div')<HeaderContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  background-color: ${({ isBg }) => (isBg ? ' #e9fffe' : ColorType.background)};
`;

const StyledDiv = styled('div')`
  display: flex;
  flex-direction: column;
  padding-right: 60px;
  align-self: center;
  @media (max-width: 9800px) {
    padding-right: 0px;
  }
`;

const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
  @media (max-width: 600px) {
    width: max-content;
  }
`;

const TitleSpan = styled('span')`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 16px;
  font-family: Rubik;
`;

const DescriptionSpan = styled('span')`
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 40px;
  color: ${ColorType.darkGrey};
  font-family: Inter;
`;

const BigTitleSpan = styled('span')`
  font-size: 32px;
  font-weight: 600;
  color: ${ColorType.black};
  font-family: Rubik;
`;

const Wrapper = styled('div')`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 63px;
  // @media (max-width: 980px) {
  //   display: block;
  // }
  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

const HeaderWrapper = styled('div')`
  width: 1050px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 63px;
  @media (max-width: 600px) {
    width: auto;
    text-align: center;
  }
`;

const IconContainer = styled('div')`
  width: 60px;
  height: 60px;
  border: 5.5px #4bb725 solid;
`;

const QrImage = styled(QRCode)`
  width: 190px;
  height: 190px;
`;
const QrContainer = styled('div')`
  border-radius: 24px;
  border: 8px ${ColorType.lightGrey} solid;
  padding: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const PlayContainer = styled('div')`
  display: inline-block;
  background-color: #000;
  padding: 5px 9px 6px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #a6a6a6;
  cursor: pointer;
  &:hover,
  &:focus {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
`;

const PlayIcon = styled(Playstore)`
  width: 24px; /* Adjust the size as needed */
  height: 24px; /* Adjust the size as needed */
  margin-right: 10px; /* Spacing between the icon and text */
  vertical-align: middle;
`;

const AppleIcon = styled(Apple)`
  width: 24px; /* Adjust the size as needed */
  height: 24px; /* Adjust the size as needed */
  margin-right: 10px; /* Spacing between the icon and text */
  vertical-align: middle;
`;

const StoresContainer = styled('div')`
  display: flex;
  gap: 16px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const StoreTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StoreCTA = styled('span')`
  color: ${ColorType.background};
  font-size: 14px;
`;

const StoreText = styled('span')`
  color: ${ColorType.background};
  font-weight: bold;
  font-size: 18px;
`;

export default Redirection;
