import axios from 'axios';
import {
  CLEAR_ERRORS,
  LOADING_UI,
  SET_CONTROLLERS,
  SET_ERRORS,
  SET_GATEWAYS,
  SET_MESSAGES,
  SET_SENSORS,
  SET_SNACK,
  UPDATE_STATUS_CONTROLLER,
} from '../types';
import { CONTROLLER, GATEWAY, SENSOR, MESSAGE } from '../../const/values';
import i18n from '../../i18n/config';

export const getGateways = (buildingId, companyId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    const res = await axios.get(
      `/gateways?${companyId && `company_id=${companyId}`}&${
        buildingId && `building_id=${buildingId}`
      }`
    );
    dispatch({ type: SET_GATEWAYS, payload: res?.data?.data });
    dispatch({ type: CLEAR_ERRORS });
  } catch (err) {
    const errors = err?.response?.data?.data;
    dispatch({ type: SET_ERRORS, payload: errors?.errors });
    const snackBar = {
      isOpen: true,
      text: errors?.message,
      severity: 'error',
    };
    dispatch({ type: SET_SNACK, payload: snackBar });
  }
};

export const getDevicesByType =
  ({ componentType, page, companyId, buildingId, keyword }) =>
  async (dispatch) => {
    try {
      // dispatch({ type: LOADING_UI });
      const url = companyId
        ? `/devices?type=${componentType}&page=${page}&company_id=${companyId}&keyword=${keyword}`
        : `/devices?type=${componentType}&page=${page}&keyword=${keyword}`;
      const urlWBuilding = buildingId
        ? `${url}&building_id=${buildingId}`
        : url;
      const res = await axios.get(urlWBuilding);
      if (componentType === CONTROLLER) {
        dispatch({ type: SET_CONTROLLERS, payload: res?.data?.data });
      } else dispatch({ type: SET_SENSORS, payload: res?.data?.data });
      dispatch({ type: CLEAR_ERRORS });
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const getMessages =
  ({ page, companyId, buildingId, keyword }) =>
  async (dispatch) => {
    try {
      // dispatch({ type: LOADING_UI });
      const url = companyId
        ? `/mqtt/logs?page=${page}&company_id=${companyId}&keyword=${keyword}`
        : `/mqtt/logs?page=${page}&keyword=${keyword}`;
      const urlBuilding = buildingId ? `${url}&building_id=${buildingId}` : url;
      const res = await axios.get(urlBuilding);
      dispatch({ type: SET_MESSAGES, payload: res?.data?.data });
      dispatch({ type: CLEAR_ERRORS });
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: 'SET_ERRORS', payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: 'SET_SNACK', payload: snackBar });
    }
  };

export const filterProducts =
  ({ filteredData, componentType }) =>
  (dispatch) => {
    // dispatch({ type: LOADING_UI });
    if (componentType === GATEWAY) {
      dispatch({ type: SET_GATEWAYS, payload: filteredData });
    }
    if (componentType === CONTROLLER) {
      dispatch({ type: SET_CONTROLLERS, payload: filteredData });
    }
    if (componentType === SENSOR) {
      dispatch({ type: SET_SENSORS, payload: filteredData });
    }
    if (componentType === MESSAGE) {
      dispatch({ type: SET_MESSAGES, payload: filteredData });
    }
    dispatch({ type: CLEAR_ERRORS });
  };

export const storeComponent =
  ({ componentType, companyId, data, navigate }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      if (componentType === GATEWAY) {
        await axios.post(`/gateway/store`, data);
      } else {
        await axios.post(
          `/device/store/${componentType}?company_id=${companyId}`,
          data
        );
      }
      dispatch({ type: CLEAR_ERRORS });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text:
          currentLanguage === 'en'
            ? `${componentType} Added!`
            : `${componentType} ajouté !`,
        severity: 'success',
      };
      navigate('/devices');
      dispatch({ type: SET_SNACK, payload: snackBar });
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const updateComponent =
  ({ componentType, componentId, data, navigate }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      if (componentType === GATEWAY) {
        await axios.post(`/gateway/update/${componentId}`, data);
      } else {
        await axios.post(`/device/update/${componentId}`, data);
      }
      dispatch({ type: CLEAR_ERRORS });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text:
          currentLanguage === 'en'
            ? `${componentType} Updated!`
            : `${componentType} à jour !`,
        severity: 'success',
      };
      navigate('/devices');
      dispatch({ type: SET_SNACK, payload: snackBar });
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const enableController =
  ({ controllerId, pin, companyId, buildingId, channel, handleClose }) =>
  async (dispatch) => {
    try {
      // dispatch({ type: LOADING_UI });
      await axios.post(`/channel/${controllerId}/${channel}/open?pin=${pin}`);
      dispatch({ type: UPDATE_STATUS_CONTROLLER, payload: controllerId });
      dispatch(
        getDevicesByType({
          componentType: CONTROLLER,
          page: 1,
          companyId,
          buildingId: buildingId,
          keyword: '',
        })
      );
      dispatch({ type: CLEAR_ERRORS });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text:
          currentLanguage === 'en'
            ? `controller Updated!`
            : 'controlleur mis à jour',
        severity: 'success',
      };
      handleClose();
      dispatch({ type: SET_SNACK, payload: snackBar });
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const disableController =
  ({ controllerId, pin, companyId, buildingId, channel, handleClose }) =>
  async (dispatch) => {
    try {
      // dispatch({ type: LOADING_UI });
      await axios.post(`/channel/${controllerId}/${channel}/close?pin=${pin}`);
      dispatch({ type: UPDATE_STATUS_CONTROLLER, payload: controllerId });
      dispatch(
        getDevicesByType({
          componentType: CONTROLLER,
          page: 1,
          companyId,
          buildingId: buildingId,
          keyword: '',
        })
      );
      dispatch({ type: CLEAR_ERRORS });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text:
          currentLanguage === 'en'
            ? `controller Updated!`
            : 'controlleur mis à jour',
        severity: 'success',
      };
      handleClose();
      dispatch({ type: SET_SNACK, payload: snackBar });
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const downloadGatewayTemplate = () => async (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios({
    url: `/gateway/template`,
    method: 'GET',
    responseType: 'blob',
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `gateway_template.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    });
};

export const downloadDeviceTemplate =
  ({ type }) =>
  async (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios({
      url: `/device/template/${type}`,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${type}_template.xlsx`); // Replace 'filename.extension' with the desired file name and extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        dispatch({ type: CLEAR_ERRORS });
      })
      .catch((err) => {
        const errors = err?.response?.data?.data;
        dispatch({ type: SET_ERRORS, payload: errors?.errors });
        const snackBar = {
          isOpen: true,
          text: errors?.message,
          severity: 'error',
        };
        dispatch({ type: SET_SNACK, payload: snackBar });
      });
  };

export const uploadGatewayFile =
  ({ file, companyId, handleClose }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      const formData = new FormData();
      formData.append('file', file);
      await axios.post(`/gateway/import?company_id=${companyId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch({ type: CLEAR_ERRORS });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text: currentLanguage === 'en' ? 'File uploaded!' : 'Fichier ajouté !',
        severity: 'success',
      };
      dispatch(getGateways());
      handleClose();
      dispatch({ type: SET_SNACK, payload: snackBar });
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text:
          currentLanguage === 'en'
            ? errors?.message
            : 'Le fichier utilisé est vide',
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const uploadFile =
  ({ type, file, companyId }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      const formData = new FormData();
      formData.append('file', file);
      await axios.post(
        `/device/import/${type}?company_id=${companyId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      dispatch({ type: CLEAR_ERRORS });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text: currentLanguage === 'en' ? 'File uploaded!' : 'Fichier ajouté !',
        severity: 'success',
      };
      // dispatch(getDevicesByType({ componentType: type }));
      dispatch({ type: SET_SNACK, payload: snackBar });
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text:
          currentLanguage === 'en'
            ? errors?.message
            : 'Le fichier utilisé est vide',
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };
