import axios from 'axios';
import {
  CLEAR_ERRORS,
  LOADING_UI,
  SET_DASHBOARD_ALERTS,
  SET_DASHBOARD_ARCHIVED_ALERTS,
  SET_DASHBOARD_BUILDINGS,
  SET_DASHBOARD_EVENTS,
  SET_ERRORS,
  SET_SNACK,
} from '../types';
import dayjs from 'dayjs';

export const getBuildingData = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    const res = await axios.get('/dashboard');
    dispatch({ type: SET_DASHBOARD_BUILDINGS, payload: res?.data?.data });
    dispatch({ type: CLEAR_ERRORS });
  } catch (err) {
    const errors = err?.response?.data?.data;
    dispatch({ type: SET_ERRORS, payload: errors?.errors });
    const snackBar = {
      isOpen: true,
      text: errors?.message,
      severity: 'error',
    };
    dispatch({ type: SET_SNACK, payload: snackBar });
  }
};

export const getAlertData = () => async (dispatch) => {
  try {
    // dispatch({ type: LOADING_UI });
    const res = await axios.get('/alerts/filter?status=pending');
    dispatch({ type: SET_DASHBOARD_ALERTS, payload: res?.data?.data });
    dispatch({ type: CLEAR_ERRORS });
  } catch (err) {
    const errors = err?.response?.data?.data;
    dispatch({ type: SET_ERRORS, payload: errors?.errors });
    const snackBar = {
      isOpen: true,
      text: errors?.message,
      severity: 'error',
    };
    dispatch({ type: SET_SNACK, payload: snackBar });
  }
};

export const getResolvedAlertsData = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    const res = await axios.get('/alerts/filter?status=archived%2Cresolved');
    dispatch({ type: SET_DASHBOARD_ARCHIVED_ALERTS, payload: res?.data?.data });
    dispatch({ type: CLEAR_ERRORS });
  } catch (err) {
    const errors = err?.response?.data?.data;
    dispatch({ type: SET_ERRORS, payload: errors?.errors });
    const snackBar = {
      isOpen: true,
      text: errors?.message,
      severity: 'error',
    };
    dispatch({ type: SET_SNACK, payload: snackBar });
  }
};

export const getEventData = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    const res = await axios.get('/events');
    const events = res?.data?.data;
    const groupedEvents = events.reduce((result, event) => {
      const date = dayjs(event?.created_at)?.format('ddd/MMM');

      if (!result[date]) {
        result[date] = [];
      }

      result[date].push(event);

      return result;
    }, {});
    dispatch({ type: SET_DASHBOARD_EVENTS, payload: groupedEvents });
    dispatch({ type: CLEAR_ERRORS });
  } catch (err) {
    const errors = err?.response?.data?.data;
    dispatch({ type: SET_ERRORS, payload: errors?.errors });
    const snackBar = {
      isOpen: true,
      text: errors?.message,
      severity: 'error',
    };
    dispatch({ type: SET_SNACK, payload: snackBar });
  }
};
