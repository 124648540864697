import axios from 'axios';
import {
  ADD_BUILDING,
  CLEAR_ERRORS,
  SET_BUILDINGS,
  LOADING_UI,
  SET_ERRORS,
  SET_SNACK,
  UPDATE_BUILDING,
  SET_BUILDING,
} from '../types';
import { getDevicesByType, getMessages } from './deviceAction';
import { CONTROLLER, SENSOR } from '../../const/values';
import { getAdminUsers, getUsers } from './usersAction';
import i18n from '../../i18n/config';

export const getBuildings = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    const res = await axios.get('/buildings?all=1');
    dispatch({ type: SET_BUILDINGS, payload: res?.data?.data });
    dispatch({ type: CLEAR_ERRORS });
  } catch (err) {
    const errors = err?.response?.data?.data;
    dispatch({ type: SET_ERRORS, payload: errors?.errors });
    const snackBar = {
      isOpen: true,
      text: errors?.message,
      severity: 'error',
    };
    dispatch({ type: SET_SNACK, payload: snackBar });
  }
};

export const getAllBuildings = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    const res = await axios.get(`/buildings?`);
    dispatch({ type: SET_BUILDINGS, payload: res?.data?.data });
    dispatch({ type: CLEAR_ERRORS });
  } catch (err) {
    const errors = err?.response?.data?.data;
    dispatch({ type: SET_ERRORS, payload: errors?.errors });
    const snackBar = {
      isOpen: true,
      text: errors?.message,
      severity: 'error',
    };
    dispatch({ type: SET_SNACK, payload: snackBar });
  }
};

export const getAdminBuildings = (companyId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    const res = await axios.get(`/buildings?company_id=${companyId}&all=1`);
    dispatch({ type: SET_BUILDINGS, payload: res?.data?.data });
    dispatch({ type: CLEAR_ERRORS });
  } catch (err) {
    const errors = err?.response?.data?.data;
    dispatch({ type: SET_ERRORS, payload: errors?.errors });
    const snackBar = {
      isOpen: true,
      text: errors?.message,
      severity: 'error',
    };
    dispatch({ type: SET_SNACK, payload: snackBar });
  }
};

export const storeBuilding =
  ({ buildingName, buildingAddress, buildingFloor, companyId, navigate }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      const data = {
        name: buildingName,
        floors: buildingFloor,
        address: buildingAddress,
        status: true,
        company_id: companyId,
        pools: 0,
      };
      const res = await axios.post('/building/store', data);
      dispatch({ type: ADD_BUILDING, payload: res?.data?.data });
      dispatch({ type: CLEAR_ERRORS });
      const snackBar = {
        isOpen: true,
        text: 'Building Added!',
        severity: 'success',
      };
      navigate('/building-management');
      dispatch({ type: SET_SNACK, payload: snackBar });
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const updateBuilding =
  ({ data, buildingId, navigate }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      const { id, ...newData } = data;
      await axios.post(`/building/update/${buildingId}`, newData);
      dispatch({ type: UPDATE_BUILDING, payload: data });
      dispatch({ type: CLEAR_ERRORS });
      const snackBar = {
        isOpen: true,
        text: 'Building Updated!',
        severity: 'success',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
      navigate('/building-management');
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const selectBuilding =
  ({ buildingId, companyId, isAdmin }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      dispatch({ type: SET_BUILDING, payload: buildingId });
      dispatch({ type: CLEAR_ERRORS });
      let msg;
      const currentLanguage = i18n.language;
      if (buildingId === 0) {
        msg =
          currentLanguage === 'en'
            ? 'Company Unselected!'
            : 'Batiment deselectionné !';
      } else {
        msg =
          currentLanguage === 'en'
            ? 'Company Selected!'
            : 'Batiment selectionné !';
      }
      const snackBar = {
        isOpen: true,
        text: msg,
        severity: 'success',
      };
      if (isAdmin) dispatch(getAdminUsers(companyId, buildingId));
      if (!isAdmin) dispatch(getUsers(buildingId));
      dispatch(
        getDevicesByType({
          componentType: CONTROLLER,
          page: 1,
          companyId,
          buildingId: buildingId,
          keyword: '',
        })
      );
      dispatch(
        getDevicesByType({
          componentType: SENSOR,
          page: 1,
          companyId,
          buildingId: buildingId,
          keyword: '',
        })
      );
      if (isAdmin) {
        dispatch(
          getMessages({
            page: 1,
            companyId,
            buildingId: buildingId,
            keyword: '',
          })
        );
      }

      dispatch({ type: SET_SNACK, payload: snackBar });
    } catch (err) {
      const errors = err?.response;
      dispatch({ type: SET_ERRORS, payload: errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };
