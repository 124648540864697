// User reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const SET_USER_FIRST_AUTH = 'SET_USER_FIRST_AUTH';
export const SET_USER_SECOND_AUTH = 'SET_USER_SECOND_AUTH';
export const UPDATE_USER = 'UPDATE_USER';

// Dashboard reducer types
export const SET_DASHBOARD_BUILDINGS = 'SET_DASHBOARD_BUILDINGS';
export const SET_DASHBOARD_ALERTS = 'SET_DASHBOARD_ALERTS';
export const SET_DASHBOARD_ARCHIVED_ALERTS = 'SET_DASHBOARD_ARCHIVED_ALERTS';
export const SET_DASHBOARD_EVENTS = 'SET_DASHBOARD_EVENTS';

// Company reducer types
export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_COMPANY = 'SET_COMPANY';
export const UNSET_COMPANY = 'UNSET_COMPANY';
export const ADD_COMPANY = 'ADD_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const TOGGLE_COMPANY_SUPPORT = 'TOGGLE_COMPANY_SUPPORT';

// Building reducer types
export const SET_BUILDINGS = 'SET_BUILDINGS';
export const SET_BUILDING = 'SET_BUILDING';
export const ADD_BUILDING = 'ADD_BUILDING';
export const UPDATE_BUILDING = 'UPDATE_BUILDING';

// Users reducer types
export const SET_USERS = 'SET_USERS';
export const UPDATE_USERS = 'UPDATE_USERS';
export const UPDATE_USERS_STATUS = 'UPDATE_USERS_STATUS';

// Devices reducer types
export const SET_GATEWAYS = 'SET_GATEWAYS';
export const SET_CONTROLLERS = 'SET_CONTROLLERS';
export const SET_DEVICE = 'SET_DEVICE';
export const SET_SENSORS = 'SET_SENSORS';
export const SET_MESSAGES = 'SET_MESSAGES';
export const UPDATE_STATUS_CONTROLLER = 'UPDATE_STATUS_CONTROLLER';
export const UPDATE_STATUS_CONTROLLER_LIVE = 'UPDATE_STATUS_CONTROLLER_LIVE';
export const UPDATE_STATUS_SENSOR = 'UPDATE_STATUS_SENSOR';

// Schedule reducer types
export const ADD_SCHEDULE = 'ADD_SCHEDULE';
export const SET_SCHEDULES = 'SET_SCHEDULES';

// UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
export const SET_SNACK = 'SET_SNACK';

// Menu reducer types
export const SET_BUILDING_MANAGER_MENU = 'SET_BUILDING_MANAGER_MENU';
export const SET_MANAGER_MENU = 'SET_MANAGER_MENU';
export const SET_ADMIN_MENU = 'SET_ADMIN_MENU';
export const SET_SUPER_ADMIN_MENU = 'SET_SUPER_ADMIN_MENU';
export const SET_BUILDING_MENU = 'SET_BUILDING_MENU';
export const SET_COMPONENT_MENU = 'SET_COMPONENT_MENU';
export const SET_SCHEDULER_MENU = 'SET_SCHEDULER_MENU';

// local reducer types
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
