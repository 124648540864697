/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import Paper from '@mui/material/Paper';

import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import { Order } from '../../const/types';
import { messagesHeadCells } from '../../const/arrays';
import { getComparator, stableSort } from '../../utils/helper';
import { GatewayDTO } from '../../redux/types/gateway.dto';
import EmptyItem from './EmptyItem';
import { styled } from '@mui/material/styles';
import { Text } from '../Text';
import { useAppDispatch, useAppSelector } from '../../utils/redux';
import { messagesSelector } from '../../redux/selectors/device';
import { MessageDTO } from '../../redux/types/message.dto';
import { getMessages } from '../../redux/actions/deviceAction';
import NewPaginationTable from './NewPaginationTable';
import { loadingSelector } from '../../redux/selectors/ui';
import { CircularProgress } from '@mui/material';
import { companyIdSelector } from '../../redux/selectors/company';
import { buildingSelector } from '../../redux/selectors/building';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const MessagesTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const messages: MessageDTO = useAppSelector(messagesSelector);
  const loading = useAppSelector(loadingSelector);
  const companyId = useAppSelector(companyIdSelector);
  const building = useAppSelector(buildingSelector);
  const [controls, setControls] = useState({
    page: 0,
    rowsPerPage: 15,
  });
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [totalPages, setTotalPages] = useState(0);
  const [visibleRows, setVisibleRows] = useState<any>([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const keyword = queryParams.get('keyword');

  useEffect(() => {
    dispatch(
      getMessages({
        page: controls.page + 1,
        companyId,
        buildingId: building?.id,
        keyword: keyword ?? '',
      })
    );
    setTotalPages(messages.meta?.last_page);
  }, [controls]);

  const updateRows = useCallback(() => {
    const newRows = stableSort(messages.items, getComparator(order, orderBy));
    setVisibleRows(newRows);
  }, [messages.items, order, orderBy]);

  useEffect(() => {
    updateRows();
  }, [messages, updateRows]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof GatewayDTO
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  if (messages?.items?.length === 0) return <EmptyItem component="Messages" />;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          backgroundColor: '#F8F9FB',
          border: '1px solid #D1D9E2',
          borderRadius: '10px',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        }}
      >
        <EnhancedTableToolbar
          title="Message"
          description={`${t('manage')} Messages`}
        />
        <TableContainer style={{ borderTop: '2px solid #D1D9E2' }}>
          {loading ? (
            <LoadingContainer>
              <CircularProgress color="primary" />
            </LoadingContainer>
          ) : (
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'medium'}
            >
              <EnhancedTableHead
                headCells={messagesHeadCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {visibleRows?.map((row: any, index: number) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      sx={{
                        borderBottom: '1px solid #D1D9E2',
                        cursor: 'pointer',
                      }}
                    >
                      <TableCell align="left">
                        <TitleDivContainer>
                          <TextContainer>
                            {/* @ts-ignore */}
                            <Text>{row.timestamp}</Text>
                          </TextContainer>
                        </TitleDivContainer>
                      </TableCell>
                      <TableCell align="left">
                        {/* @ts-ignore */}
                        <Text>{row.devEui}</Text>
                      </TableCell>
                      <TableCell align="left">
                        {/* @ts-ignore */}
                        <Text>{row.building?.name}</Text>
                      </TableCell>
                      <TableCell align="left">
                        {/* @ts-ignore */}
                        <Text>{row.deviceModelDescription}</Text>
                      </TableCell>
                      <TableCell align="left">
                        <Text>
                          {JSON.stringify(row.dataDecoded).replace(
                            /[{}"]/g,
                            ''
                          )}
                        </Text>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <NewPaginationTable
          page={controls.page}
          totalPages={totalPages}
          setControls={setControls}
        />
      </Paper>
    </Box>
  );
};

const TitleDivContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const LoadingContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

export default MessagesTable;
