import axios from 'axios';
import {
  ADD_SCHEDULE,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_ERRORS,
  SET_SCHEDULES,
  SET_SNACK,
} from '../types';
import i18n from '../../i18n/config';

export const getSchedules = (companyId) => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    const res = await axios.get(`/schedules?company_id=${companyId}`);
    dispatch({ type: SET_SCHEDULES, payload: res?.data?.data });
    dispatch({ type: CLEAR_ERRORS });
  } catch (err) {
    const errors = err?.response?.data?.data;
    dispatch({ type: SET_ERRORS, payload: errors?.errors });
    const snackBar = {
      isOpen: true,
      text: errors?.message,
      severity: 'error',
    };
    dispatch({ type: SET_SNACK, payload: snackBar });
  }
};

export const storeSchedule =
  (data, companyId, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      await axios.post(`/schedule/store?company_id=${companyId}`, data);
      dispatch(getSchedules(companyId));
      dispatch({ type: CLEAR_ERRORS });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text:
          currentLanguage === 'en'
            ? 'Schedule Added!'
            : 'Planificateur Ajouté !',
        severity: 'success',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
      handleClose();
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const updateSchedule =
  (data, scheduleId, companyId, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      await axios.post(`/schedule/update/${scheduleId}`, data);
      dispatch(getSchedules(companyId));
      dispatch({ type: CLEAR_ERRORS });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text:
          currentLanguage === 'en'
            ? 'Schedule Updated!'
            : 'Planificateur mis à jour !',
        severity: 'success',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
      handleClose();
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const deleteSchedule =
  (scheduleId, companyId, handleClose) => async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      await axios.post(`/schedule/delete/${scheduleId}`);
      dispatch(getSchedules(companyId));
      dispatch({ type: CLEAR_ERRORS });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text:
          currentLanguage === 'en'
            ? 'Schedule Deleted!'
            : 'Planificateur supprimé !',
        severity: 'success',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
      handleClose();
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const toggleSchedule =
  (data, scheduleId, companyId) => async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      await axios.post(`/schedule/update/${scheduleId}`, data);
      dispatch(getSchedules(companyId));
      dispatch({ type: CLEAR_ERRORS });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text:
          currentLanguage === 'en'
            ? 'Schedule Updated!'
            : 'Planificateur mis à jour !',
        severity: 'success',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };
