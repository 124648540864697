import { useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import { TableContainer } from '@mui/material';
import { styled } from '@mui/system';
import ColorType from '../Theme/ColorType';
import { Plus } from '../svg';
import { ActionButton } from '../Button';
import ImportModal from '../Modal/ImportModal';
import { Theme } from '@mui/system';
import { useTranslation } from 'react-i18next';

type Props = {
  component: string;
  icon?: JSX.Element;
  handleClick: () => void;
};
const style = (theme: Theme) => ({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  borderRadius: '12px',
  padding: '16px 32px 24px 32px',
  backgroundColor: theme.palette.mode === 'dark' ? '#0A1929' : 'white',
  boxShadow: `0px 2px 24px ${
    theme.palette.mode === 'dark' ? '#000' : '#383838'
  }`,
});

const EmptyComponent = ({ component, icon, handleClick }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          border: '1px solid #D1D9E2',
          borderRadius: '10px',
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
        }}
      >
        <div
          style={{
            backgroundColor: '#F8F9FB',
            borderBottom: '1px solid #D1D9E2',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          }}
        >
          <EnhancedTableToolbar
            title={component}
            description={`${t('manage')} ${component}s`}
            isWithButton
            handleButtonClick={handleClick}
          />
        </div>
        <TableContainer
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 40,
          }}
        >
          {icon && icon}
          <Title>
            {t('empty-component-title-1')} {component}{' '}
            {t('empty-component-title-2')}
          </Title>
          <Description>
            {t('empty-component-desc')} {component}
          </Description>
          <ActionButton
            buttonLabel={`${t('import-csv-start')} .csv ${t('import-csv-end')}`}
            color={'light'}
            iconLeft={<PlusIcon stroke="black" />}
            OnClick={handleOpen}
            style={{
              borderRadius: 6,
              border: '1px solid #D1D9E2',
            }}
          />
          <ImportModal open={open} handleClose={handleClose} style={style} />
        </TableContainer>
      </Paper>
    </Box>
  );
};

const Title = styled('span')`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${ColorType.darkGrey};
  margin-top: 24px;
  text-align: center;
`;

const Description = styled('span')`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${ColorType.softGrey};
  margin-bottom: 16px;
  text-align: center;
`;

const PlusIcon = styled(Plus)(() => ({
  width: 20,
  height: 20,
  marginRight: '6px',
  stroke: ColorType.black,
}));

export default EmptyComponent;
