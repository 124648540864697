import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { SearchInput } from '../Input';
import { Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../utils/redux';
import {
  gatewaysSelector,
  messagesSelector,
} from '../../redux/selectors/device';
import { CONTROLLER, GATEWAY, MESSAGE, SENSOR } from '../../const/values';
import {
  filterProducts,
  getDevicesByType,
  getGateways,
  getMessages,
} from '../../redux/actions/deviceAction';
import { GatewayDTO } from '../../redux/types/gateway.dto';
import { MessageDTO } from '../../redux/types/message.dto';
import { buildingSelector } from '../../redux/selectors/building';
import { isAdminSelector, isManagerSelector } from '../../redux/selectors/user';
import { useTranslation } from 'react-i18next';
import { companyIdSelector } from '../../redux/selectors/company';
import { useNavigate } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ComponentTabsProps {
  GatewayChildren?: React.ReactNode;
  ControllerChildren?: React.ReactNode;
  SensorsChildren?: React.ReactNode;
  MessagesChildren?: React.ReactNode;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ComponentsTab = ({
  GatewayChildren,
  ControllerChildren,
  SensorsChildren,
  MessagesChildren,
}: ComponentTabsProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const gateways: GatewayDTO = useAppSelector(gatewaysSelector);
  const messages: MessageDTO = useAppSelector(messagesSelector);
  const [value, setValue] = useState(0);
  const companyId = useAppSelector(companyIdSelector);
  const building = useAppSelector(buildingSelector);
  const isAdmin = useAppSelector(isAdminSelector);
  const isManager = useAppSelector(isManagerSelector);
  const isSuper = isAdmin || isManager;

  useEffect(() => {
    const storedValue = localStorage.getItem('selectedTab');
    if (storedValue !== null) {
      setValue(parseInt(storedValue, 10));
    }
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    localStorage.setItem('selectedTab', newValue.toString());
    navigate('');
  };

  const filterSearch = (text: string) => {
    if (text.length < 2) {
      value === 0 && dispatch(getGateways(building?.id, companyId));
      value === 1 &&
        dispatch(
          getDevicesByType({
            componentType: CONTROLLER,
            page: 1,
            companyId,
            buildingId: building?.id,
            keyword: '',
          })
        );
      value === 2 &&
        dispatch(
          getDevicesByType({
            componentType: SENSOR,
            page: 1,
            companyId,
            buildingId: building?.id,
            keyword: '',
          })
        );
      value === 3 &&
        dispatch(
          getMessages({
            page: 1,
            companyId,
            buildingId: building?.id,
            keyword: '',
          })
        );
      return;
    }
    if (value === 0) {
      const items = gateways.items.filter((item: any) => {
        const itemData = `${item.name.toUpperCase()}`;
        const textData = text.toUpperCase();

        return itemData.indexOf(textData) > -1;
      });
      const meta = gateways.meta;
      const filteredData = { items, meta };
      dispatch(
        filterProducts({
          filteredData: filteredData ?? [],
          componentType: GATEWAY,
        })
      );
    }
    if (value === 1) {
      dispatch(
        getDevicesByType({
          componentType: CONTROLLER,
          page: 1,
          companyId,
          buildingId: building?.id,
          keyword: text,
        })
      );
    }
    if (value === 2) {
      dispatch(
        getDevicesByType({
          componentType: SENSOR,
          page: 1,
          companyId,
          buildingId: building?.id,
          keyword: text,
        })
      );
    }
    if (value === 3) {
      dispatch(
        getMessages({
          page: 1,
          companyId,
          buildingId: building?.id,
          keyword: text,
        })
      );
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid
        container
        style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}
      >
        <Grid item sx={{ flex: 2 }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label={t('devices-gateway')}
              sx={{
                fontFamily: 'Inter',
                fontSize: 15,
                fontWeight: 600,
                textTransform: 'none',
              }}
              {...a11yProps(0)}
            />
            <Tab
              label={t('devices-Controller')}
              sx={{
                fontFamily: 'Inter',
                fontSize: 15,
                fontWeight: 600,
                textTransform: 'none',
              }}
              {...a11yProps(1)}
            />
            <Tab
              label={t('devices-Sensor')}
              sx={{
                fontFamily: 'Inter',
                fontSize: 15,
                fontWeight: 600,
                textTransform: 'none',
              }}
              {...a11yProps(2)}
            />
            {isAdmin && (
              <Tab
                label={t('devices-event-messages')}
                sx={{
                  fontFamily: 'Inter',
                  fontSize: 15,
                  fontWeight: 600,
                  textTransform: 'none',
                }}
                {...a11yProps(2)}
              />
            )}
          </Tabs>
        </Grid>
        <Grid
          item
          style={{
            flex: 1,
            paddingRight: 32,
            paddingLeft: 8,
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <SearchInput
            placeholder={t('input-search')}
            type="text"
            OnChange={filterSearch}
          />
        </Grid>
      </Grid>
      <CustomTabPanel value={value} index={0}>
        {GatewayChildren}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {ControllerChildren}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {SensorsChildren}
      </CustomTabPanel>
      {isAdmin && (
        <CustomTabPanel value={value} index={3}>
          {MessagesChildren}
        </CustomTabPanel>
      )}
    </Box>
  );
};
