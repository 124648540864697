import { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { FormButton } from '../Button';
import { DialogContent, DialogActions } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../utils/redux';
import { DropzoneArea } from 'material-ui-dropzone';
import { CircularProgress, Typography } from '@mui/material';
import {
  downloadDeviceTemplate,
  downloadGatewayTemplate,
  uploadFile,
  uploadGatewayFile,
} from '../../redux/actions/deviceAction';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { companyIdSelector } from '../../redux/selectors/company';
import ColorType from '../Theme/ColorType';
import { useTranslation } from 'react-i18next';

type PopupProps = {
  open: boolean;
  handleClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
};

const ImportModal = ({ open, handleClose }: PopupProps) => {
  const { t, i18n } = useTranslation();
  const activeLocale = i18n.resolvedLanguage;
  const companyId = useAppSelector(companyIdSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [value, setValue] = useState('gateway');
  const dispatch = useAppDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleDownloadGateway = () => {
    dispatch(downloadGatewayTemplate());
  };
  const handleDownloadController = () => {
    dispatch(downloadDeviceTemplate({ type: 'controller' }));
  };
  const handleDownloadSensor = () => {
    dispatch(downloadDeviceTemplate({ type: 'sensor' }));
  };

  const handleFileUpload = (files: File[]) => {
    if (files.length > 0) {
      setIsLoading(true);
      setTimeout(() => {
        setUploadedFiles(files);
        setIsLoading(false);
      }, 2000);
    }
  };

  const handleSendData = () => {
    if (uploadedFiles.length > 0) {
      if (value === 'gateway')
        return dispatch(
          uploadGatewayFile({ file: uploadedFiles[0], companyId, handleClose })
        );
      dispatch(uploadFile({ type: value, file: uploadedFiles[0], companyId }));
    }
  };

  return (
    <TransparentDialog onClose={handleClose} open={open}>
      <DialogTitleContainer>{t('devices-import-title')}</DialogTitleContainer>
      <DialogContentContainer>
        <div>
          <FormLabel>{t('devices-import-type')}</FormLabel>
          <RadioGroup
            name="component-type"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="gateway"
              control={<Radio />}
              label={t('devices-import-gateway')}
            />
            <FormControlLabel
              value="controller"
              control={<Radio />}
              label={t('devices-import-controller')}
            />
            <FormControlLabel
              value="sensor"
              control={<Radio />}
              label={t('devices-import-sensor')}
            />
          </RadioGroup>
        </div>
        <div>
          <DropzoneArea
            acceptedFiles={[
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/vnd.ms-excel',
            ]}
            dropzoneText={t('devices-import-message')}
            onChange={handleFileUpload}
            filesLimit={1}
            showPreviews={true}
            showPreviewsInDropzone={false}
            useChipsForPreview
            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
            // previewChipProps={{ minWidth: 160, maxWidth: 210 }}
            previewText={t('devices-import-preview')}
            {...(activeLocale === 'fr' && {
              getFileAddedMessage: (file) =>
                `Fichier ${file} ajouté avec succès.`,
            })}
          />
          {isLoading && (
            <div style={{ textAlign: 'center', marginTop: 10 }}>
              <CircularProgress />
              <Typography variant="caption">
                {t('devices-import-uploading')}
              </Typography>
            </div>
          )}
        </div>
        <TemplateContainer>
          <TemplateWrapper>
            <Title>{t('devices-import-file')}</Title>
            <Description>{t('devices-import-text')}</Description>
          </TemplateWrapper>
          <div
            style={{
              gap: '16px',
              display: 'flex',
            }}
          >
            <Button>
              <ButtonText onClick={handleDownloadGateway}>
                {t('devices-import-gfile')}
              </ButtonText>
            </Button>
            <Button>
              <ButtonText onClick={handleDownloadController}>
                {t('devices-import-cfile')}
              </ButtonText>
            </Button>
            <Button>
              <ButtonText onClick={handleDownloadSensor}>
                {t('devices-import-sfile')}
              </ButtonText>
            </Button>
          </div>
        </TemplateContainer>
      </DialogContentContainer>
      <DialogActionsContainer>
        <FormButton
          buttonLabel={t('button-cancel')}
          color={'grey'}
          OnClick={handleClose}
          style={{
            marginRight: 10,
            borderRadius: 6,
            border: '1px solid #F0F0F0',
          }}
        />
        <FormButton
          buttonLabel={t('button-confirm')}
          color={'primary'}
          OnClick={handleSendData}
          style={{
            borderRadius: 6,
          }}
        />
      </DialogActionsContainer>
    </TransparentDialog>
  );
};

const TransparentDialog = styled(Dialog)(() => ({
  '& .MuiBackdrop-root': {
    backgroundColor: '#00000033',
  },
  '& .MuiDialog-paper': {
    width: '80%',
    maxWidth: '80%',
    borderRadius: 16,
  },
}));

const DialogTitleContainer = styled(DialogTitle)(() => ({
  paddingTop: 32,
  paddingInline: 48,
}));

const DialogContentContainer = styled(DialogContent)(() => ({
  paddingTop: '32px !important',
  paddingInline: 30,
  paddingBottom: 32,
}));

const DialogActionsContainer = styled(DialogActions)(() => ({
  paddingInline: 48,
  paddingBottom: 32,
}));

const Link = styled('span')(() => ({
  textDecoration: 'underline',
  color: ColorType.primary,
  cursor: 'pointer',
}));

const TemplateContainer = styled('div')`
  padding: 24px;
  background: ${ColorType.infoBackground};
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-top: 32px;
`;

const TemplateWrapper = styled('div')`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: flex;
`;

const Title = styled('span')`
  color: ${ColorType.infoDark};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02px;
`;

const Description = styled('span')`
  color: ${ColorType.darkGrey};
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.02px;
`;

const Button = styled('div')`
  padding: 10px 16px;
  background: ${ColorType.background};
  border-radius: 6px;
  border: 0.5px ${ColorType.softGrey} solid;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: flex;
  cursor: pointer;
`;

const ButtonText = styled('span')`
  text-align: center;
  color: ${ColorType.darkGrey};
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
`;
export default ImportModal;
