import axios from 'axios';
import {
  ADD_COMPANY,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_BUILDING_MENU,
  SET_COMPANIES,
  SET_COMPANY,
  SET_COMPONENT_MENU,
  SET_ERRORS,
  SET_SCHEDULER_MENU,
  SET_SNACK,
  TOGGLE_COMPANY_SUPPORT,
  UNSET_COMPANY,
  UPDATE_COMPANY,
} from '../types';
import {
  getAdminBuildings,
  getBuildings,
  selectBuilding,
} from './buildingAction';
import i18n from '../../i18n/config';

export const getCompanies = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    const res = await axios.get('/companies');
    dispatch({ type: SET_COMPANIES, payload: res?.data?.data });
    dispatch({ type: CLEAR_ERRORS });
  } catch (err) {
    const errors = err?.response?.data?.data;
    dispatch({ type: SET_ERRORS, payload: errors?.errors });
    const snackBar = {
      isOpen: true,
      text: errors?.message,
      severity: 'error',
    };
    dispatch({ type: SET_SNACK, payload: snackBar });
  }
};

export const storeCompany =
  ({ companyName, companyAddress, navigate }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      const data = {
        name: companyName,
      };
      const res = await axios.post('/company/store', data);
      dispatch({ type: ADD_COMPANY, payload: res?.data?.data });
      dispatch({ type: CLEAR_ERRORS });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text:
          currentLanguage === 'en' ? 'Company Added!' : 'Entreprise ajoutée !',
        severity: 'success',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
      navigate('/company-management');
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const updateCompany =
  ({ companyId, companyName, companyAddress, isAdmin, status, navigate }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      const data = {
        name: companyName,
        address: companyAddress,
        enabled: isAdmin ? status : true,
      };
      await axios.post(`/company/update/${companyId}`, data);
      dispatch({ type: UPDATE_COMPANY, payload: data });
      dispatch({ type: CLEAR_ERRORS });
      const currentLanguage = i18n.language;
      const snackBar = {
        isOpen: true,
        text:
          currentLanguage === 'en'
            ? 'Company Updated!'
            : 'Entreprise mise à jour !',
        severity: 'success',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
      navigate('/company-management');
    } catch (err) {
      const errors = err?.response?.data?.data;
      dispatch({ type: SET_ERRORS, payload: errors?.errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const selectCompany =
  ({ companyId }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING_UI });
      let msg;
      const currentLanguage = i18n.language;
      if (companyId === 0) {
        dispatch({ type: UNSET_COMPANY });
        msg =
          currentLanguage === 'en'
            ? 'Company Unselected!'
            : 'Entreprise deselectionnée !';
      } else {
        dispatch({ type: SET_COMPANY, payload: companyId });
        msg =
          currentLanguage === 'en'
            ? 'Company Selected!'
            : 'Entreprise selectionnée !';
      }
      dispatch(
        selectBuilding({
          buildingId: 0,
          companyId,
          isAdmin: true,
        })
      );
      dispatch({ type: CLEAR_ERRORS });
      const snackBar = {
        isOpen: true,
        text: msg,
        severity: 'success',
      };

      if (companyId === 0) dispatch(getBuildings());
      else dispatch(getAdminBuildings(companyId));
      dispatch({ type: SET_BUILDING_MENU });
      dispatch({ type: SET_COMPONENT_MENU });
      dispatch({ type: SET_SCHEDULER_MENU });
      dispatch({ type: SET_SNACK, payload: snackBar });
    } catch (err) {
      const errors = err?.response;
      dispatch({ type: SET_ERRORS, payload: errors });
      const snackBar = {
        isOpen: true,
        text: errors?.message,
        severity: 'error',
      };
      dispatch({ type: SET_SNACK, payload: snackBar });
    }
  };

export const toggleCompanySupport = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING_UI });
    await axios.post(`/company/toggle/support`);
    dispatch({ type: TOGGLE_COMPANY_SUPPORT });
    dispatch({ type: CLEAR_ERRORS });
    const currentLanguage = i18n.language;
    const snackBar = {
      isOpen: true,
      text:
        currentLanguage === 'en'
          ? 'Company Support enabled!'
          : 'Support entreprise activé',
      severity: 'success',
    };
    dispatch({ type: SET_SNACK, payload: snackBar });
  } catch (err) {
    const errors = err?.response?.data?.data;
    dispatch({ type: SET_ERRORS, payload: errors?.errors });
    const snackBar = {
      isOpen: true,
      text: errors?.message,
      severity: 'error',
    };
    dispatch({ type: SET_SNACK, payload: snackBar });
  }
};
