import { InputAdornment, TextField } from '@mui/material';
import s from './style.module.css';
import FormHelperText from '@mui/material/FormHelperText';
import { Search } from '../svg';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

type InputType = {
  label?: string;
  type?: string;
  placeholder?: string;
  hasBorder?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  OnChange: (value: string) => void;
};
const SearchInput = ({
  label,
  type,
  placeholder,
  OnChange,
  hasBorder = false,
  disabled,
  error,
  errorMessage,
}: InputType) => {
  const navigate = useNavigate();
  const [text, setText] = useState('');
  const handleEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    //@ts-expect-error value exists
    const value: string = event.target.value;
    if (event.key === 'Enter') {
      OnChange(value);
      navigate(`?keyword=${encodeURIComponent(value)}`);
    } else if (event.key === 'Backspace' && value.length <= 1) {
      OnChange(value);
      navigate('');
    }
  };

  return (
    <div className={s.searchContainer}>
      {label && (
        <FormHelperText id="label" className={s.label}>
          {label}
        </FormHelperText>
      )}
      <TextField
        error={error}
        value={text}
        helperText={errorMessage}
        id="input"
        fullWidth
        type={type ? type : 'text'}
        placeholder={placeholder}
        variant="outlined"
        className={`${hasBorder ? s.textFieldWithBorder : s.textField} ${
          disabled && s.textFieldGrey
        }`}
        disabled={disabled}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={(event) => handleEnter(event)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search width={20} height={20} />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default SearchInput;
