/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useNavigate } from 'react-router';
import ColorType from '../Theme/ColorType';
import { styled } from '@mui/material/styles';
import { SwitchStatus } from '../Input';
import { Status } from '../Badge';
import {
  BatteryHigh,
  BatteryLow,
  BatteryMedium,
  Circle,
  Clog,
  Sensor,
} from '../svg';
import { displayLastSeen, displayStatusDevices } from '../../utils/helper';
import { SensorItemDTO } from '../../redux/types/sensor.dto';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Text } from '../Text';

type Props = {
  sensorRow: SensorItemDTO;
  sensorIndex: number;
  setIsControllerOpen: any;
  handleChangeStatus: (controller: any) => void;
};

const SensorItem = ({
  sensorRow,
  sensorIndex,
  setIsControllerOpen,
  handleChangeStatus,
}: Props) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const labelId = `enhanced-table-checkbox-${sensorIndex}`;
  const circleBg = displayStatusDevices(
    sensorRow.status === 'open' || sensorRow.status === 'online'
      ? 'active'
      : sensorRow.status === 'closed'
      ? 'inactive'
      : 'offline'
  ).circleBg;
  const bgColor = displayStatusDevices(
    sensorRow.status === 'open' || sensorRow.status === 'online'
      ? 'active'
      : sensorRow.status === 'closed'
      ? 'inactive'
      : 'offline'
  ).bgColor;
  const lastSeenSensor = displayLastSeen(
    /* @ts-ignore */
    sensorRow?.last_seen,
    /* @ts-ignore */
    sensorRow?.status === 'offline'
  );
  const devices = sensorRow?.controllers;

  const displayBattery = (battery: number) => {
    if (battery > 50)
      return (
        <BatteryContainer>
          <BatteryHigh height={'10px'} width={'18px'} />
          <BatteryText color={ColorType.softGreen}>{battery}%</BatteryText>
        </BatteryContainer>
      );
    if (battery < 50 && battery > 15)
      return (
        <BatteryContainer>
          <BatteryMedium height={'10px'} width={'18px'} />
          <BatteryText color={ColorType.softOrange}>{battery}%</BatteryText>
        </BatteryContainer>
      );
    if (battery < 15)
      return (
        <BatteryContainer>
          <BatteryLow height={'10px'} width={'18px'} />
          <BatteryText color={ColorType.primary}>{battery}%</BatteryText>
        </BatteryContainer>
      );
  };

  const handleClick = (row: any) => {
    navigate(`sensor/${row.id}`, { state: { sensor: row } });
  };

  const handleClickController = (row: any) => {
    navigate(`controller/${row.id}`, { state: { controller: row } });
  };

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        key={sensorRow.id}
        sx={{
          borderBottom: '1px solid #D1D9E2',
          cursor: 'pointer',
        }}
      >
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          sx={{ paddingLeft: '20px' }}
          onClick={() => handleClick(sensorRow)}
        />
        <TableCell align="left">
          <TitleDivContainer>
            <AvatarContainer>
              <Sensor
                height={'14px'}
                width={'14px'}
                fill={ColorType.lightGrey}
                onClick={() => handleClick(sensorRow)}
              />
            </AvatarContainer>
            <TextContainer>
              <Text color={ColorType.grey500}>{sensorRow.eui}</Text>
            </TextContainer>
            {sensorRow.controllers.length > 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  setOpen(!open);
                  setIsControllerOpen(!open);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TitleDivContainer>
        </TableCell>
        <TableCell align="left" onClick={() => handleClick(sensorRow)}>
          <Text color={ColorType.grey50}>{sensorRow.model}</Text>
        </TableCell>
        <TableCell align="left" onClick={() => handleClick(sensorRow)}>
          <Text color={ColorType.grey50}>{sensorRow.building?.name}</Text>
        </TableCell>
        <TableCell align="left" onClick={() => handleClick(sensorRow)}>
          <Text color={ColorType.grey50}>
            {sensorRow.device_location?.floor}
          </Text>
        </TableCell>
        <TableCell align="left" onClick={() => handleClick(sensorRow)}>
          <Text color={ColorType.grey50}>
            {sensorRow.device_location?.floor_area}
          </Text>
        </TableCell>
        <TableCell align="left" onClick={() => handleClick(sensorRow)}>
          <Text color={ColorType.grey50}>
            {sensorRow.device_location?.floor_sub_area}
          </Text>
        </TableCell>
        <TableCell align="right" onClick={() => handleClick(sensorRow)}>
          <Status
            text={sensorRow.status_label || ''}
            isShowCircle
            circleColor={circleBg}
            bgColor={bgColor}
          />
          <LastSeenRow>
            {/* @ts-ignore */}
            {sensorRow?.status === 'closed' && (
              <Circle fill={ColorType.primary} />
            )}
            <LastSeenText>{lastSeenSensor}</LastSeenText>
          </LastSeenRow>
        </TableCell>
        <TableCell align="right">
          {sensorRow?.battery && displayBattery(Number(sensorRow.battery))}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleClick(sensorRow)}
        ></TableCell>
        <TableCell
          align="center"
          onClick={() => handleClick(sensorRow)}
        ></TableCell>
      </TableRow>
      {open &&
        devices &&
        devices.map((row) => {
          // const circleBg = displayStatusDevices(
          //   row.status ? 'active' : 'inactive'
          // ).circleBg;
          // const bgColor = displayStatusDevices(
          //   row.status ? 'active' : 'inactive'
          // ).bgColor;

          const channelA = row.channels?.find((c) => c.channel_code === 'A');
          const channelB = row.channels?.find((c) => c.channel_code === 'B');

          const circleBgA = displayStatusDevices(
            channelA?.status === 'open'
              ? 'active'
              : channelA?.status === 'closed'
              ? 'inactive'
              : 'offline'
          ).circleBg;
          const bgColorA = displayStatusDevices(
            channelA?.status === 'open'
              ? 'active'
              : channelA?.status === 'closed'
              ? 'inactive'
              : 'offline'
          ).bgColor;

          const circleBgB = displayStatusDevices(
            channelB?.status === 'open'
              ? 'active'
              : channelB?.status === 'closed'
              ? 'inactive'
              : 'offline'
          ).circleBg;
          const bgColorB = displayStatusDevices(
            channelB?.status === 'open'
              ? 'active'
              : channelB?.status === 'closed'
              ? 'inactive'
              : 'offline'
          ).bgColor;
          // const lastSeenController = displayLastSeen(
          //   /* @ts-ignore */
          //   row?.last_seen,
          //   /* @ts-ignore */
          //   row?.status === 'offline'
          // );
          const lastSeenControllerA = displayLastSeen(
            /* @ts-ignore */
            row?.last_seen,
            /* @ts-ignore */
            row.channels?.find((c) => c.channel_code === 'A')?.status ===
              'offline'
          );
          const lastSeenControllerB = displayLastSeen(
            /* @ts-ignore */
            row?.last_seen,
            /* @ts-ignore */
            row.channels?.find((c) => c.channel_code === 'B')?.status ===
              'offline'
          );
          // const status = row?.status === 'open';

          const statusA = channelA?.status === 'open';
          const statusB = channelB?.status === 'open';

          // @ts-expect-error type
          const hasChannelA = sensorRow.assigned_controllers.some(
            // @ts-expect-error type
            (ac) =>
              ac.controller_id === row.id &&
              (ac.channels === 'A' || ac.channels === 'AB')
          );

          // @ts-expect-error type
          const hasChannelB = sensorRow.assigned_controllers.some(
            // @ts-expect-error type
            (ac) =>
              ac.controller_id === row.id &&
              (ac.channels === 'B' || ac.channels === 'AB')
          );

          return (
            <TableRow
              hover
              tabIndex={-1}
              key={row.id}
              sx={{
                borderBottom: '1px solid #D1D9E2',
                cursor: 'pointer',
              }}
              // onClick={() => handleClickController(row)}
            >
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="none"
                sx={{ paddingLeft: '10px' }}
                onClick={() => handleClickController(row)}
              />
              <TableCell
                align="left"
                onClick={() => handleClickController(row)}
              >
                <TitleDivContainer>
                  <AvatarContainer>
                    <Clog
                      height={'14px'}
                      width={'14px'}
                      fill={ColorType.lightGrey}
                    />
                  </AvatarContainer>
                  <TextContainer>
                    <Text color={ColorType.grey500}>{row.eui}</Text>
                  </TextContainer>
                </TitleDivContainer>
              </TableCell>
              <TableCell
                align="left"
                onClick={() => handleClickController(row)}
              >
                <Text color={ColorType.grey50}>{row.model}</Text>
              </TableCell>
              <TableCell
                align="left"
                onClick={() => handleClickController(row)}
              >
                <Text color={ColorType.grey50}>{row.building?.name}</Text>
              </TableCell>
              <TableCell
                align="left"
                onClick={() => handleClickController(row)}
              >
                <Text color={ColorType.grey50}>
                  {row.device_location?.floor}
                </Text>
              </TableCell>
              <TableCell
                align="left"
                onClick={() => handleClickController(row)}
              >
                <Text color={ColorType.grey50}>
                  {row.device_location?.floor_area}
                </Text>
              </TableCell>
              <TableCell
                align="left"
                onClick={() => handleClickController(row)}
              >
                <Text color={ColorType.grey50}>
                  {row.device_location?.floor_sub_area}
                </Text>
              </TableCell>
              <TableCell align="right">
                {hasChannelA && channelA && (
                  <>
                    <Status
                      text={channelA.status_label}
                      isShowCircle
                      circleColor={circleBgA}
                      bgColor={bgColorA}
                    />
                    {/*@ts-ignore */}
                    {/* {row?.status === 'closed' && (
                  <Circle fill={ColorType.primary} />
                )} */}
                    {channelA.status === 'closed' && (
                      <Circle fill={ColorType.primary} />
                    )}

                    <LastSeenText>{lastSeenControllerA}</LastSeenText>
                  </>
                )}
              </TableCell>
              <TableCell align="right">
                {hasChannelA && channelA && (
                  <SwitchStatus
                    key={row.id}
                    checked={statusA}
                    handleCheckedChange={() => {
                      const newRow = { ...row };
                      //@ts-expect-error new channel
                      newRow.channel = 'A';
                      //@ts-expect-error new status
                      newRow.channel_status = channelA.status;
                      handleChangeStatus(newRow);
                    }}
                  />
                )}
              </TableCell>
              <TableCell align="right">
                {hasChannelB && channelB && (
                  <>
                    <Status
                      text={channelB.status_label}
                      isShowCircle
                      circleColor={circleBgB}
                      bgColor={bgColorB}
                    />
                    {/*@ts-ignore */}
                    {/* {row?.status === 'closed' && (
                  <Circle fill={ColorType.primary} />
                )} */}
                    {channelB.status === 'closed' && (
                      <Circle fill={ColorType.primary} />
                    )}

                    <LastSeenText>{lastSeenControllerB}</LastSeenText>
                  </>
                )}
              </TableCell>
              <TableCell align="right">
                {hasChannelB && channelB && (
                  <SwitchStatus
                    key={row.id}
                    checked={statusB}
                    handleCheckedChange={() => {
                      const newRow = { ...row };
                      //@ts-expect-error new channel
                      newRow.channel = 'B';
                      //@ts-expect-error new status
                      newRow.channel_status = channelB.status;
                      handleChangeStatus(newRow);
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          );
        })}
    </>
  );
};

const TitleDivContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AvatarContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  background-color: ${ColorType.darkGrey};
  border-radius: 50px;
`;

const BatteryContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  align-items: center;
`;

type TextProps = {
  color: string;
};
const BatteryText = styled('span')<TextProps>`
  font-size: 12px;
  font-weight: 600;
  line-height: 120%;
  color: ${({ color }) => color};
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const LastSeenText = styled('span')`
  font-size: 10px;
  color: ${ColorType.darkGrey};
  font-family: Inter;
`;

const LastSeenRow = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 2px;
  gap: 2px;
`;

export default SensorItem;
